.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  color: #00889a;
}

.research-link:hover{
  text-decoration: none;
  background-color: #1565c0;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.research-link-disable {
  pointer-events: none;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  width: 220px;
  padding: 8px 22px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  margin-top: 40px;
  background-color: #00889a;
}

.research-link {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  width: 220px;
  padding: 8px 22px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  margin-top: 40px;
  background-color: #00889a;
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.btn-primary {
  background: linear-gradient(to right, #0062cc, #007bff);
  border: none;
  transition: all .3s ease-in-out;
}

.btn-secondary {
  background: #00889a;
  border: none;
  transition: pa;
  padding: 15px;
  margin-right: 10px;
  color: white;
  text-decoration: none;
}

.btn:hover {
  opacity: 0.8;
  transform: scale(1.1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

::-moz-selection {
  background: #00889a5c;
}
::-webkit-selection {
  background: #00889a5c;
}
::selection {
  background: #00889a5c;
}

.MuiMenu-list{
  background-color: white !important;
}

.highlight {
  background-color: yellow;
}

q {
  color: gray;
  font-style: italic;
}

textarea{
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 7px;
  padding: 10px;
  min-height: 100px;
  resize: none;
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;

}

.panel-accordion {
  background-color: #efefef !important;
}

#scroll-dialog-title {
  font-size: 14px !important;
}

.detail-prompt-input {
  width: auto !important;
}
#action-buttons {
  margin-top: 30px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

#search-container{
  margin: auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  transition: all .3s ease-in-out;
  margin-bottom: 180px;
}
/* #log-warp{
  max-height:300px;
  overflow-y: auto;
} */
/* #report-container{
  background-color: rgba(255,255,255,0.1);
  border: none;
  color: #fff;
  transition: all .3s ease-in-out;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);

  border-radius: 12px;
} */

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone p {
  margin: 0;
  color: #333;
}

.dropzone h4 {
  margin-top: 20px;
}

.dropzone ul {
  list-style: none;
}

.file-upload-box{
  width: 50%;
  margin: 0 auto;
  border-style: dotted;
  padding: 100px;
  border-color: #00889a;
  border-radius: 15px;
}

.dnd-box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.accepted-file {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1025px) and (max-width: 2048px) {
  #search-container {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  /* hide it elsewhere */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
